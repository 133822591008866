<template>
  <div>
    <v-row justify="center">
      <v-col cols="11">
        <h1 class="mb-10 mt-5">
          <img width="80" src="../../assets/img/linguo.png" /> LINGUO
        </h1>
        <v-btn class="mb-10" color="primary" rounded @click="send()">
          mejorar
        </v-btn>
        <vue-editor ref="editor" v-model="html"></vue-editor>
        <h2 class="my-10">RESULTADO</h2>
        <vue-editor ref="editor" v-model="html2"></vue-editor>
        <p class="mb-1 mt-10">
          <strong> TOKENS: </strong> {{ token.total_tokens }}
        </p>
      </v-col>
    </v-row>

    <v-overlay :value="loading">
      <v-progress-circular :size="120" color="#3957B9" indeterminate>
        Procesando...
      </v-progress-circular>
    </v-overlay>
  </div>
</template>
<script>
import Api from "@/config/api";
import { VueEditor } from "vue2-editor";
export default {
  components: {
    VueEditor,
  },
  data() {
    return {
      html: "",
      html2: "",
      token: {
        prompt_tokens: "",
        completion_tokens: "",
      },
      loading: false,
    };
  },
  methods: {
    send() {
      var data = {
        instruction: "corregir ortografía, redacción, ser empatico",
        input: this.html,
      };

    //   const https = require("https");
    //   axios({
    //     url: url,
    //     method: "GET",
    //     headers: {
    //       "Content-Type": "application/json",
    //     },
    //     responseType: "json",
    //     httpsAgent: new https.Agent({ rejectUnauthorized: false }),
    //   })
    //     .then((res) => {
    //       this.loading = false;
    //       this.html2 = res.data[0].choices[0].text;
    //       this.token = res.data[0].usage;
    //     })
    //     .catch((error) => {
    //         console.log(error);
    //     });

        this.loading = true;
        Api.Auth()
          .spelling(data)
          .then((res) => {
            console.log(res.data.choices)
            this.loading = false;
            this.html2 = res.data.choices[0].text;
            this.token = res.data.usage;
          })
          .catch((error) => {
            this.loading = false;
            console.log(error);
          });
    },
  },
};
</script>
